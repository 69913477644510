
import {Component, Vue} from "vue-property-decorator";
import TamamlananGorevList from "@/components/lists/TamamlananGorevList.vue";
import DevamEdenGorevList from "@/components/lists/DevamEdenGorevList.vue";
import {Getter} from "vuex-class";

@Component({
  components:{DevamEdenGorevList, TamamlananGorevList}
})
export default class IslemlerView extends Vue {
  @Getter isTokenExis!: boolean;
  mounted() {
    this.$store.dispatch('getTasksFromServer');
  }
}
