
// @ts-nocheck

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IDefaultValuable} from "@/interface/IDefaultValuable";

@Component({})
export default class ObjectInputMixin extends Vue implements IDefaultValuable {

  @Prop() value: any;
  @Prop() item: any;
  @Prop({
    type: Object, default: () => ({
    })
  }) defaultValues!: any;

  localValue = Object.assign(
      {},
      this.item ? this.item : {},
      this.value ? this.value : {},
  );

  @Watch('value')
  private onValueChangeLocal() {
    this.localValue = Object.assign({}, this.value ? this.value : {});
    this.onValueChange()
  }

  mounted(){
    this.setDefaultValues();
    if(this.localValue){
      this.onValueChange();
    }
  }


  @Watch('item')
  private onItemChangeLocal() {
    this.localValue = Object.assign({}, this.item ? this.item : {});
  }

  input(value: any = null, isChangeData: boolean = false) {
    // localValue elle değiştirmek için datayı ve onayladığını(true) yolla
    // isChangeData yanlışlıkla datayı değiştirmemek için ek onaylama görevi görür
    if (value && isChangeData)
      this.localValue=value;
    this.$emit('input', this.localValue);
    this.$emit('value', this.localValue);
    this.$forceUpdate();
  }

  setDefaultValues() {
    if (this.defaultValues) {
      Object.keys(this.defaultValues).forEach((defaultValueKey: string) => {
        let found = false;
        Object.keys(this.localValue).forEach((key: string) => {
          if (defaultValueKey === key) {
            found = true;
            if (!this.localValue[key]) {
              this.localValue[key] = this.defaultValues[key];
            }
          }
        });
        if (!found) {
          this.localValue[defaultValueKey] = this.defaultValues[defaultValueKey];
        }
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onValueChange() {
  }

}
