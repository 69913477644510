
import {Component, Vue} from "vue-property-decorator";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {ITask} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru, TaskTuruLabel} from "@/plugins/uyap-plugin/enum/TaskTuru";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import JSZip from "jszip";
import * as FileSaver from "file-saver";

@Component({
  components: {DeleteDialog}
})

export default class TamamlananGorevList extends Vue {
  taskDurum = TaskDurum;
  taskTuruLabel = TaskTuruLabel;
  taskTuru = TaskTuru;
  isDeleteModeActive = false;
  silinecekTasks: Array<ITask> = [];
  deleting = false;

  get tasks(): Array<ITask> {
    return this.$store.getters.getTamamlananGorevler;
  }

  headers = [
    {text: "İşlem Türü", value: "islemTuru"},
    {text: "Açıklama", value: "aciklama"},
    {text: "Durum", value: "durum", sortable: false, align: 'center'},
    {text: "Oluşturulma Tarihi", value: "olusturulmaTarihi", sortable: false},
    {text: "Tamamlanma Tarihi", value: "tamamlanmaTarihi", sortable: false},
    {text: "İşlemler", value: "actions", sortable: false, width: 150, align: 'center'},
  ];

  selectAll() {
    if (this.silinecekTasks.length == this.tasks.length) {
      this.silinecekTasks = []
    } else {
      this.silinecekTasks = [...this.tasks];
    }
  }

  async downloadImzali(task: ITask) {
    await this.$helper.download(TaskTuruLabel[(task.task_type_id as TaskTuru)] + new Date(task.updated_at).toISOString().substr(0, 10) + '.zip', '/api/v1/download-zip/' + task.id);
  }

  async downloadHtml(task: ITask, type: 'html' | 'word' | 'pdf') {
    if (type == "html")
      await this.$helper.download('HTML Sonuçlar.zip', '/api/v1/export-to-html/' + task.id);
    else if (type == "pdf")
      await this.$helper.download('PDF Sonuçlar.zip', '/api/v1/export-to-pdf/' + task.id);
    else if (type == "word")
      await this.wordDownload(task);
  }

  async wordDownload(task: ITask) {
    let zip = new JSZip();
    let childTasks: Array<ITask> = (await this.$http.get('/api/v1/task/' + task.id + '/child-tasks')).data;
    for (const cTask of childTasks) {
      let path = (cTask.birim_adi + " - " + cTask.dosya_esas_no).replace('/', '_');
      let sorgulananAlanlar = cTask.data.sorguAlanlari.map((item: any) => item.name);
      let hataliAlanlar = cTask.response.data.hataliSorguAlanlari.map((item: any) => item.name);
      let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
          "xmlns:w='urn:schemas-microsoft-com:office:word' " +
          "xmlns='http://www.w3.org/TR/REC-html40'>" +
          "<head><meta charset='utf-8'><title>Save File</title></head><body>";
      let footer = "</body></html>";
      // dosya alanaları
      if (hataliAlanlar.indexOf('dosyahesap') == -1 && sorgulananAlanlar.indexOf('dosyahesap') > 0) {
        let sourceHTML = header + cTask.response.data.dosya.dosyaHesapBilgileri.html + footer;
        const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
        zip.file(path + "/Dosya Hesap Bilgileri.doc", file);
      }
      if (hataliAlanlar.indexOf('tahred') == -1 && sorgulananAlanlar.indexOf('tahred') > 0) {
        let sourceHTML = header + cTask.response.data.dosya.tahsilatReddiyat.html + footer;
        const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
        zip.file(path + "/Tahsilat Reddiyat.doc", file);
      }
      // borçlu alanları
      for (let borclu of cTask.response.data.borclular.items) {
        let borcluPath = "/" + (borclu.kurumAdi ? borclu.kurumAdi : (borclu.adi + " " + borclu.soyadi));
        if (hataliAlanlar.indexOf('postaceki') == -1 && sorgulananAlanlar.indexOf('postaceki') > 0) {
          let sourceHTML = header + borclu.postaCeki.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/Posta Çeki.doc", file);
        }
        if (hataliAlanlar.indexOf('bankahesap') == -1 && sorgulananAlanlar.indexOf('bankahesap') > 0) {
          let sourceHTML = header + borclu.bankaBilgileri.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/Banka Hesap Bilgileri.doc", file);
        }
        if (hataliAlanlar.indexOf('takbis') == -1 && sorgulananAlanlar.indexOf('takbis') > 0) {
          let sourceHTML = header + borclu.takbis.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/Takbis.doc", file);
        }
        if (hataliAlanlar.indexOf('dib') == -1 && sorgulananAlanlar.indexOf('dib') > 0) {
          let sourceHTML = header + borclu.dib.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/Dış İşleri Bilgileri.doc", file);
        }
        if (hataliAlanlar.indexOf('gib') == -1 && sorgulananAlanlar.indexOf('gib') > 0) {
          let sourceHTML = header + borclu.gib.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/GİB.doc", file);
        }
        if (hataliAlanlar.indexOf('gsm') == -1 && sorgulananAlanlar.indexOf('gsm') > 0) {
          let sourceHTML = header + borclu.gsm.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/GSM.doc", file);
        }
        if (hataliAlanlar.indexOf('iski') == -1 && sorgulananAlanlar.indexOf('iski') > 0) {
          let sourceHTML = header + borclu.iski.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/İSKİ.doc", file);
        }
        if (hataliAlanlar.indexOf('mernis') == -1 && sorgulananAlanlar.indexOf('mernis') > 0) {
          let sourceHTML = header + borclu.mersis.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/Mernis.doc", file);
        }
        if (hataliAlanlar.indexOf('sgkhaciz') == -1 && sorgulananAlanlar.indexOf('sgkhaciz') > 0) {
          let sourceHTML = header + borclu.sgkhaciz.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/SGK Haciz.doc", file);
        }
        if (hataliAlanlar.indexOf('icradosyakaydi') == -1 && sorgulananAlanlar.indexOf('icradosyakaydi') > 0) {
          let sourceHTML = header + borclu.icraDosyaKaydi.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/İcra Dosya Kaydı.doc", file);
        }
        if (hataliAlanlar.indexOf('egm') == -1 && sorgulananAlanlar.indexOf('egm') > 0) {
          let sourceHTML = header + borclu.egm.html + footer;
          const file = new Blob([sourceHTML], {type: 'application/vnd.ms-word;charset=utf-8'});
          zip.file(path + borcluPath + "/EGM.doc", file);
        }
      }
    }
    let contents = await zip.generateAsync({type: "blob"})
    FileSaver.saveAs(contents, 'Word Sorgu Sonuçları ' + new Date(task.updated_at).toISOString().substr(0, 10));
  }

  async download(task: ITask) {
    if (task.task_type_id == TaskTuru.mts_odeme)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/mts-odeme-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.harc_masraf_ode)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/harc-masraf-odeme-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.evrak_gonder)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/evrak-gonder-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.mts_tebligat_gonder)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/mts-tebligat-gonder-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.makbuz_indir || task.task_type_id == TaskTuru.evrak_indir || task.task_type_id == TaskTuru.hazir_evrak_gonder)
      await this.downloadImzali(task);
    else
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/export-to-excel/' + task.id);
  }

  onDelete(task: ITask) {
    this.$store.dispatch('deleteAllTasks', task);
  }

  async onDeleteMultiple() {
    try {
      this.deleting = true;
      this.isDeleteModeActive = false;
      await this.$http.post('/api/v1/task/toplu-sil', {tasks_ids: this.silinecekTasks.map((item: ITask) => item.id)});
      this.silinecekTasks = [];
    } catch (e) {
      this.$toast.error('Silme işleminde hata oluştu. ' + e.message);
    } finally {
      await this.$store.dispatch('getTasksFromServer');
      this.deleting = false;
    }
  }

  detayaGit(task: ITask) {
    switch (task.task_type_id) {
      case this.taskTuru.takip_hazirla:
        this.$router.push('/takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.takip_ac:
        this.$router.push('/takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_hazirla:
        this.$router.push('/mts-takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_ac:
        this.$router.push('/mts-takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.takip_sorgula:
        this.$router.push('/takip-sorgula-detay/' + task.id);
        break;
      case this.taskTuru.evrak_gonder:
        this.$router.push('/evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.hazir_evrak_gonder:
        this.$router.push('/hazir-evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.makbuz_indir:
        this.$router.push('/makbuz-indir-detay/' + task.id);
        break;
      case this.taskTuru.toplu_genel_talep_hazirla:
        this.$router.push('/genel-talep-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_tebligat_hazirla:
        this.$router.push('/tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_haciz_gonder:
        this.$router.push('/haciz-gonder-detay/' + task.id);
        break;
      case this.taskTuru.harc_masraf_ode:
        this.$router.push('/harc-masraf-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_odeme:
        this.$router.push('/mts-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_tebligat_gonder:
        this.$router.push('/mts-tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.evrak_indir:
        this.$router.push('/evrak-indir-detay/' + task.id);
        break;
    }
  }
}
