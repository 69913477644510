
import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {IProcessedTaskChilds, ITask, PlayerDurum} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {TaskTuru, TaskTuruLabel} from "@/plugins/uyap-plugin/enum/TaskTuru";
import draggable from "vuedraggable";
import {Getter} from "vuex-class";

@Component({
  components: {DeleteDialog, draggable}
})

export default class DevamEdenGorevList extends Vue {
  @Getter isUyapConnected!: boolean;
  @Getter getSiradakiGorevler!: Array<ITask>;
  @Getter getChildTasksStatus!: Array<IProcessedTaskChilds>;
  taskDurum = TaskDurum;
  taskTuru = TaskTuru;
  taskTuruLabel = TaskTuruLabel;
  playerDurum = PlayerDurum;
  onFly = false;
  isDeleteModeActive = false;
  deleting = false;
  silinecekTasks = [];
  headers = [
    {text: "", value: "drag"},
    {text: "İşlem Türü", value: "islemTuru"},
    {text: "Açıklama", value: "aciklama"},
    {text: "Oluşturulma Tarihi", value: "created_at"},
    {text: "Durum", value: "durum", sortable: false, align: 'center'},
    {text: "İşlemler", value: "actions", width: '120', sortable: false, align: 'center'},
  ];

  get tamamlanmaDurumu() {
    let bitendosyalar = this.getChildTasksStatus.filter((child: IProcessedTaskChilds) => [TaskDurum.BITTI_BASARILI, TaskDurum.BITTI_HATALI, TaskDurum.BITTI_EKSIK, TaskDurum.YENIDEN_BASLATILDI].indexOf(child.status) != -1);
    let yuzde = (100 / this.getChildTasksStatus.length) * bitendosyalar.length;
    return yuzde;
  }

  get progressMessage() {
    let bitendosyalar = this.getChildTasksStatus.filter((child: IProcessedTaskChilds) => [TaskDurum.BITTI_BASARILI, TaskDurum.BITTI_HATALI, TaskDurum.BITTI_EKSIK, TaskDurum.YENIDEN_BASLATILDI].indexOf(child.status) != -1);
    return "İşlemler üzerinde çalışılıyor... (" + bitendosyalar.length + "/" + this.getChildTasksStatus.length + ")";
  }

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  get disableDrag() {
    return !!this.getSiradakiGorevler.find(task => task.status == TaskDurum.ISLENIYOR) || this.onFly;
  }

  async downloadHtml(task: ITask, type: 'html' | 'word' | 'pdf') {
    if (type == "html")
      await this.$helper.download('HTML Sonuçlar.zip', '/api/v1/export-to-html/' + task.id);
    else if (type == "pdf")
      await this.$helper.download('PDF Sonuçlar.zip', '/api/v1/export-to-pdf/' + task.id);
    else if (type == "word")
      await this.wordDownload(task);
  }

  async download(task: ITask) {
    if (task.task_type_id == TaskTuru.mts_odeme)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/mts-odeme-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.harc_masraf_ode)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/harc-masraf-odeme-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.evrak_gonder)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/v1/evrak-gonder-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.mts_tebligat_gonder)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/v1/mts-tebligat-gonder-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.hazir_evrak_gonder)
      await this.$helper.download('Excel Sonuçlar.xlsx', '/v1/hazir-evrak-export-excel/' + task.id);
    else if (task.task_type_id == TaskTuru.makbuz_indir || task.task_type_id == TaskTuru.evrak_indir)
      await this.downloadImzali(task);
    else
      await this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/export-to-excel/' + task.id);
  }

  async wordDownload(task: ITask) {
    let filteredTask = {...task};
    filteredTask.childTasks = (task.childTasks as Array<ITask>).filter(childTask => childTask.status == this.taskDurum.BITTI_BASARILI || childTask.status == this.taskDurum.BITTI_EKSIK);
    await this.$uyapHelper.takipSorgulaWordDownload(filteredTask);
  }

  async downloadImzali(task: ITask) {
    await this.$helper.download(TaskTuruLabel[(task.task_type_id as TaskTuru)] + new Date(task.updated_at).toISOString().substr(0, 10) + '.zip', '/api/v1/download-zip/' + task.id);
  }

  onDelete(task: ITask) {
    this.$store.dispatch('deleteAllTasks', task);
  }

  async onDeleteMultiple() {
    try {
      this.deleting = true;
      this.isDeleteModeActive = false;
      await this.$http.post('/api/v1/task/toplu-sil', {tasks_ids: this.silinecekTasks.map((item: ITask) => item.id)});
      this.silinecekTasks = [];
    } catch (e) {
      this.$toast.error('Silme işleminde hata oluştu. ' + e.message);
    } finally {
      await this.$store.dispatch('getTasksFromServer');
      this.deleting = false;
    }
  }

  onEnd(evt: any) {
    if (evt.oldIndex == evt.newIndex)
      return;
    this.onFly = true;
    let liste = [...this.getSiradakiGorevler];
    let changedElement = liste[evt.oldIndex];
    changedElement.oncelik_sirasi = evt.newIndex + 1;
    liste.splice(evt.oldIndex, 1);
    if (evt.oldIndex > evt.newIndex) {
      for (let i = evt.newIndex; i < liste.length; i++) {
        liste[i].oncelik_sirasi = i + 2;
      }
    } else if (evt.oldIndex < evt.newIndex) {
      for (let i = evt.newIndex - 1; i >= 0; i--) {
        liste[i].oncelik_sirasi = i + 1;
      }
    }
    liste.push(changedElement);
    liste.sort((a, b) => (a.oncelik_sirasi - b.oncelik_sirasi));
    this.$http.post('/api/v1/task/onceliksirasi', liste).then(() => {
      this.$store.dispatch('getTasksFromServer').finally(() => {
        this.$socket.client.emit("updateTasks");
        this.onFly = false;
      });
    });
  }

  async durdurDevamEt() {
    if (this.$store.getters.getPlayerDurum == this.playerDurum.oto) {
      this.$socket.client.emit("player", this.playerDurum.durduruluyor);
    } else {
      this.$socket.client.emit("player", this.playerDurum.oto);
    }
  }

  detayaGit(task: ITask) {
    switch (task.task_type_id) {
      case this.taskTuru.takip_hazirla:
        this.$router.push('/takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.takip_ac:
        this.$router.push('/takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_hazirla:
        this.$router.push('/mts-takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_ac:
        this.$router.push('/mts-takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.takip_sorgula:
        this.$router.push('/takip-sorgula-detay/' + task.id);
        break;
      case this.taskTuru.evrak_gonder:
        this.$router.push('/evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.hazir_evrak_gonder:
        this.$router.push('/hazir-evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.makbuz_indir:
        this.$router.push('/makbuz-indir-detay/' + task.id);
        break;
      case this.taskTuru.toplu_genel_talep_hazirla:
        this.$router.push('/genel-talep-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_tebligat_hazirla:
        this.$router.push('/tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_haciz_gonder:
        this.$router.push('/haciz-gonder-detay/' + task.id);
        break;
      case this.taskTuru.harc_masraf_ode:
        this.$router.push('/harc-masraf-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_odeme:
        this.$router.push('/mts-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_tebligat_gonder:
        this.$router.push('/mts-tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.evrak_indir:
        this.$router.push('/evrak-indir-detay/' + task.id);
        break;
    }
  }
}
